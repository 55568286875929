export const kataList = [
    {
        id: 1,
        name: 'Taikyoku Shodan',
        kanji: '太極初段',
        meaning: 'First cause',
        counts: '20',
        first: '8',
        second: '16',
        url: 'https://www.youtube.com/embed/yzIQJsRJzEk?rel=0',
    },

    {
        id: 2,
        name: 'Heian Shodan',
        kanji: '平安初段',
        meaning: 'Peaceful mind, Level 1',
        counts: '21',
        first: '9',
        second: '17',
        url: 'https://www.youtube.com/embed/UW8dWNeu8ik?rel=0',
    },
    {
        id: 3,
        name: 'Heian Nidan',
        kanji: '平安二段',
        meaning: 'Peaceful mind, Level 2',
        counts: '26',
        first: '11',
        second: '26',
        url: 'https://www.youtube.com/embed/tJyAn3jb3hY?rel=0',
    },

    {
        id: 4,
        name: 'Heian Sandan',
        kanji: '平安三段',
        meaning: 'Peaceful mind, Level 3',
        counts: '20',
        first: '10',
        second: '20',
        url: 'https://www.youtube.com/embed/CmUmAdxfO_o?rel=0',
    },

    {
        id: 5,
        name: 'Heian Yondan',
        kanji: '平安四段',
        meaning: 'Peaceful mind, Level 4',
        counts: '27',
        first: '13',
        second: '25',
        url: 'https://www.youtube.com/embed/TtkkIxL29po?rel=0',
    },

    {
        id: 6,
        name: 'Heian Godan',
        kanji: '平安五段',
        meaning: 'Peaceful mind, Level 5',
        counts: '23',
        first: '12',
        second: '19',
        url: 'https://www.youtube.com/embed/A2wBlKGn2lA?rel=0',
    },

    {
        id: 7,
        name: 'Tekki Shodan',
        kanji: '鉄騎初段',
        meaning: 'Iron horse, Level 1',
        counts: '29',
        first: '15',
        second: '29',
        url: 'https://www.youtube.com/embed/0sVMmHpfmxw?rel=0',
    },

    {
        id: 8,
        name: 'Tekki Nidan',
        kanji: '鉄騎二段',
        meaning: 'Iron horse, Level 2',
        counts: '24',
        first: '16',
        second: '24',
        url: 'https://www.youtube.com/embed/9uqw0g3E4BY?rel=0',
    },

    {
        id: 9,
        name: 'Tekki Sandan',
        kanji: '鉄騎三段',
        meaning: 'Iron horse, Level 3',
        counts: '36',
        first: '16',
        second: '36',
        url: 'https://www.youtube.com/embed/-bmF_F2bDoc?rel=0',
    },

    {
        id: 10,
        name: 'Bassai Dai',
        kanji: '披塞大',
        meaning: 'To storm a fortress, Big ',
        counts: '42',
        first: '19',
        second: '42',
        url: 'https://www.youtube.com/embed/hE-SvaJ42oY?rel=0',
    },

    {
        id: 11,
        name: 'Kanku Dai',
        kanji: '観空大',
        meaning: 'To look to the sky, Big',
        counts: '65',
        first: '15',
        second: '65',
        url: 'https://www.youtube.com/embed/gQtiJgiRX6s?rel=0',
    },

    {
        id: 12,
        name: 'Jion',
        kanji: '慈陰',
        meaning: 'Named after a temple ',
        counts: '47',
        first: '17',
        second: '47',
        url: 'https://www.youtube.com/embed/RXeMNYiHRsU?rel=0',
    },

    {
        id: 13,
        name: 'Empi',
        kanji: '燕飛',
        meaning: 'Flying swallow',
        counts: '37',
        first: '15',
        second: '36',
        url: 'https://www.youtube.com/embed/hNJnsmQ_G5o?rel=0',
    },

    {
        id: 14,
        name: 'Hangetsu',
        kanji: '半月',
        meaning: 'Half moon',
        counts: '41',
        first: '11',
        second: '40',
        url: 'https://www.youtube.com/embed/dsOBD_KRZNo?rel=0',
    },

    {
        id: 15,
        name: 'Jitte',
        kanji: '十手',
        meaning: 'Ten hands',
        counts: '24',
        first: '13',
        second: '24',
        url: 'https://www.youtube.com/embed/AqkNX7HugT8?rel=0',
    },

    {
        id: 16,
        name: 'Gankaku',
        kanji: '岩鶴',
        meaning: 'Crane on rock',
        counts: '42',
        first: '28',
        second: '42',
        url: 'https://www.youtube.com/embed/EOcTPixo8ls?rel=0',
    },

    {
        id: 17,
        name: 'Sochin',
        kanji: '壯鎭',
        meaning: 'To preserve peace',
        counts: '41',
        first: '30',
        second: '41',
        url: 'https://www.youtube.com/embed/fykP48OFJpU?rel=0',
    },

    {
        id: 18,
        name: 'Nijushiho',
        kanji: '二十四步',
        meaning: '24 steps',
        counts: '34',
        first: '18',
        second: '33',
        url: 'https://www.youtube.com/embed/6z4Ufr_SZX0?rel=0',
    },

    {
        id: 19,
        name: 'Gojushiho Sho',
        kanji: '五十四歩小',
        meaning: '54 steps, Small',
        counts: '65',
        first: '57',
        second: '64',
        url: 'https://www.youtube.com/embed/W-Ul-F-Otsk?rel=0',
    },

    {
        id: 20,
        name: 'Gojushiho Dai',
        kanji: '五十四歩大',
        meaning: '54 steps, Big',
        counts: '67',
        first: '59',
        second: '66',
        url: 'https://www.youtube.com/embed/WB0OIpcD_0U?rel=0',
    },

    {
        id: 21,
        name: 'Bassai Sho',
        kanji: '披塞小',
        meaning: 'To storm a fortress, Small',
        counts: '27',
        first: '17',
        second: '22',
        url: 'https://www.youtube.com/embed/2vX-AfzTEO0?rel=0',
    },

    {
        id: 22,
        name: 'Kanku Sho',
        kanji: '観空小',
        meaning: 'To look to the sky, Small',
        counts: '48',
        first: '6',
        second: '48',
        url: 'https://www.youtube.com/embed/R1KqSlnu4JE?rel=0',
    },

    {
        id: 23,
        name: 'Wankan',
        kanji: '王冠',
        meaning: 'Emperor’s crown',
        counts: '24',
        first: '24',
        second: 'n/a',
        url: 'https://www.youtube.com/embed/py_aMWc4HDo?rel=0',
    },

    {
        id: 24,
        name: 'Meikyo',
        kanji: '明渠',
        meaning: 'Bright Mirror',
        counts: '33',
        first: '32',
        second: 'n/a',
        url: 'https://youtube.com/embed/MDbZdXCdEVE?rel=0',
    },

    {
        id: 25,
        name: ' Chinte',
        kanji: '珍手',
        meaning: 'Amazing hands',
        counts: '33',
        first: '28',
        second: '32',
        url: 'https://youtube.com/embed/ou14EWbogB0?rel=0',
    },

    {
        id: 26,
        name: 'Unsu',
        kanji: '雲手',
        meaning: 'Hands in the cloud',
        counts: '48',
        first: '36',
        second: '48',
        url: 'https://youtube.com/embed/pnnslPBsoUI?rel=0',
    },
]
