export const trainingTypesList = [
    {
        id: '1',
        name: 'Kihon',
        description:
            'Kihon (基本, きほん) is a Japanese term meaning "basics" or "fundamentals." The term is used to refer to the basic techniques that are taught and practiced as the foundation of most Japanese martial arts.',
        image: 'assets/kihon.png',
    },
    {
        id: '2',
        name: 'Kata',
        description:
            'Kata (型 or 形, カタ) is a Japanese term meaning "form". It refers to a detailed choreographed pattern of martial arts movements made to be practised alone. It can also be reviewed within groups and in unison when training.',
        image: 'assets/kata.png',
    },
    {
        id: '3',
        name: 'Kumite',
        description:
            'Kumite (組手, literally "grappling hands") refers to sparring. Kumite is the part of karate in which a person trains against an opponent.',
        image: 'assets/kumite.png',
    },
]
