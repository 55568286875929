// CONSIDER: POSITION ABSOLUTE ON VIDEO
// background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(176,74,227,1) 35%, rgba(176,74,227,1) 100%)
import { Nav, Navbar, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Pages
import Home from './pages/Home'
import Join from './pages/Join'
import Beginners from './pages/Beginners'
import Members from './pages/Members'
import Instructor from './pages/Instructor'
import AboutJka from './pages/AboutJka'
import Contact from './pages/Contact'

// Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import NotFound from './pages/NotFound'
import Footer from './shared/Footer'

function MainBody() {
    const MainHeader = () => {
        return (
            <div className='main'>
                <Router>
                    <Navbar
                        bg='dark'
                        variant='dark'
                        expand='lg'
                        className='mb-0 '
                        collapseOnSelect
                    >
                        <Container>
                            <Navbar.Brand href='/'>
                                <img
                                    src={'../assets/jka-header.svg'}
                                    className='header-image center img-fluid'
                                    alt='Japan Karate Association'
                                />
                            </Navbar.Brand>

                            <Navbar.Toggle aria-controls='basic-navbar-nav' />
                            <Navbar.Collapse
                                id='basic-navbar-nav'
                                className='justify-content-end'
                            >
                                <Nav className=''>
                                    <LinkContainer to='/'>
                                        <Nav.Link>Home</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/about-jka'>
                                        <Nav.Link>About JKA</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/instructor'>
                                        <Nav.Link>Instructor</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/beginners'>
                                        <Nav.Link>Beginners</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/members'>
                                        <Nav.Link>Members</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/join'>
                                        <Nav.Link>Join</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/contact'>
                                        <Nav.Link>Contact</Nav.Link>
                                    </LinkContainer>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/about-jka' element={<AboutJka />} />
                        <Route path='/instructor' element={<Instructor />} />
                        <Route path='/beginners' element={<Beginners />} />
                        <Route path='/members' element={<Members />} />
                        <Route path='/join' element={<Join />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </Router>
            </div>
        )
    }

    // http://reactcommunity.org/react-transition-group/with-react-router/

    const MainFooter = () => {
        return (
            <footer>
                <Footer />
            </footer>
        )
    }

    return (
        <>
            <MainHeader />
            <MainFooter />
        </>
    )
}

function App() {
    return (
        <div className='App'>
            <MainBody />
        </div>
    )
}

export default App
