export const syllabusList = [
    {
        id: 10,
        title: '10th Kyu',
        beltColour: 'white',
        beltTitle: 'White Belt',
        kihonTitle: 'In place basics in SHIZEN TAI',
        kihonList: [
            'CHUUDAN CHOKUZUKI',
            'JOUDAN AGEUKE',
            'CHUUDAN SOTOUKE',
            'GEDAN BARAI',
            'MAEGERI (HEISOKU DACHI, GEDAN KAKIWAKE)',
        ],
    },
    {
        id: 9,
        title: '9th Kyu',
        beltColour: 'yellow-white',
        beltTitle: 'Yellow Belt with White Stripe',
        kihonTitle:
            'In place basics in SHIZEN TAI to ZENKUTSU DACHI and back to SHIZEN TAI',
        kihonList: [
            'CHUUDAN JUNZUKI',
            'JOUDAN AGEUKE',
            'CHUUDAN SOTOUKE',
            'MAEGERI (HEISOKU DACHI, GEDAN KAKIWAKE)',
        ],
    },
    {
        id: 8,
        title: '8th Kyu',
        beltColour: 'yellow',
        beltTitle: 'Yellow Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE (step in)',
            'CHUUDAN SOTOUKE (step in)',
            'GEDAN BARAI (step in)',
            'KOKUTSU SHUTOU UKE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'YOKOGERI KEAGE (in HEISOKU DACHI, right and left 2 times each)',
        ],
        kata: 'TAIKYOKU SHOUDAN',
        kumite: 'GOHON KUMITE (JOUDAN JUNZUKI, CHUUDAN JUNZUKI)',
    },
    {
        id: 7,
        title: '7th Kyu',
        beltColour: 'orange',
        beltTitle: 'Orange Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE (step back)',
            'CHUUDAN SOTOUKE (step in)',
            'CHUUDAN UCHIUKE (step back)',
            'KOUKUTSU SHUTOU UKE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'YOKOGERI KEAGE (in KIBA DACHI, stepping in, right and left)',
        ],
        kata: 'HEIAN SHOUDAN',
        kumite: 'GOHON KUMITE (JOUDAN JUNZUKI, CHUUDAN JUNZUKI)',
    },
    {
        id: 6,
        title: '6th Kyu',
        beltColour: 'green',
        beltTitle: 'Green Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE (step back)',
            'CHUUDAN SOTOUKE (step in)',
            'CHUUDAN UCHIUKE (step back)',
            'KOUKUTSU SHUTOU UKE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'YOKOGERI KEAGE (in KIBA DACHI, right and left) (step in)',
            'YOKOGERI KEKOMI (in KIBA DACHI, right and left) (step in)',
        ],
        kata: 'HEIAN NIDAN',
        kumite: 'KIHON IPPON KUMITE (JOUDAN JUNZUKI – right and left, CHUUDAN JUNZUKI – right and left',
    },

    {
        id: 5,
        title: '5th Kyu',
        beltColour: 'blue',
        beltTitle: 'Blue Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE, GYAKU ZUKI (step back)',
            'CHUUDAN SOTOUKE, GYAKU ZUKI (step in)',
            'CHUUDAN UCHIUKE, CHUUDAN GYAKU ZUKI (step back)',
            'KOUKUTSU SHUTOU UKE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'YOKOGERI KEAGE (in KIBA DACHI, right and left) (step in)',
            'YOKOGERI KEKOMI (in KIBA DACHI, right and left) (step in)',
        ],
        kata: 'HEIAN SANDAN',
        kumite: 'KIHON IPPON KUMITE (JOUDAN JUNZUKI – right and left, CHUUDAN JUNZUKI – right and left. CHUUDAN MAEGERI – right and left, GEDAN KAKIWAKE – right and left)',
    },

    {
        id: 4,
        title: '4th Kyu',
        beltColour: 'purple',
        beltTitle: 'Purple Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE, GYAKU ZUKI (step back)',
            'CHUUDAN SOTOUKE, GYAKU ZUKI (step in)',
            'CHUUDAN UCHIUKE, GYAKU ZUKI (step back)',
            'KOUKUTSU SHUTOU UKE, ZENKUTSU NUKITE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'YOKOGERI KEAGE (in KIBA DACHI, right and left) (step in)',
            'YOKOGERI KEKOMI (in KIBA DACHI, right and left) (step in)',
        ],
        kata: 'HEIAN YONDAN',
        kumite: 'KIHON IPPON KUMITE (JOUDAN JUNZUKI – right and left, CHUUDAN JUNZUKI – right and left. CHUUDAN MAEGERI GEDAN KAKIWAKE – right and left, CHUUDAN YOKOGERI KEKOMI – right and left)',
    },
    {
        id: 3,
        title: '3rd Kyu',
        beltColour: 'brown',
        beltTitle: 'Brown Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE, GYAKU ZUKI (step back)',
            'CHUUDAN SOTOUKE, GYAKUZUKI (step in)',
            'CHUUDAN UCHIUKE, GYAKU ZUKI (step back)',
            'KOUKUTSU SHUTOU UKE, ZENKUTSU NUKITE (step in)',
            'MAEREGI (GEDAN KAKIWAKE) (step in)',
            'REN GERI (GEDAN KAKIWAKE CHUUDAN, JOUDAN) (step in)',
            'MAWASHIGERI',
            'YOKOGERI KEAGE (in KIBA DACHI, right and left) (step in)',
            'YOKOGERI KEKOMI (in KIBA DACHI, right and left) (step in)',
        ],
        kata: 'HEIAN GODAN',
        kumite: 'KIHON IPPON KUMITE. JOUDAN JUNZUKI – right and left, CHUUDAN JUNZUKI – right and left. GEDAN KAKIWAKE – right and left, CHUDAN YOKOGERI KEKOMI – right and left)',
    },

    {
        id: 2,
        title: '2nd Kyu',
        beltColour: 'brown',
        beltTitle: 'Brown Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'JOUDAN AGEUKE, GYAKU ZUKI (step back)',
            'CHUUDAN SOTOUKE in ZENKUTSU DACHI, changing stance to KIBA DACHI, YOKO ENPI, YOKO URAKEN UCHI (step in)',
            'CHUUDAN UCHIUKE, GYAKU ZUKI (step back)',
            'KOUKUTSU SHUTOU UKE, ZENKUTSU NUKITE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'REN GERI (GEDAN KAKIWAKE, CHUUDAN, JOUDAN) (step in)',
            'MAWASHI GERI (step in)',
            'YOKOGERI KEAGE (in KIBA DACHI, right and left) (step in)',
            'YOKOGERI KEKOMI (in ZENKUTSUDATI) (step in)',
        ],
        kata: 'TEKKI SHODAN',
        kumite: 'JIYUU IPPON KUMITE (JODAN JUNZUKI, CHUUDAN JUNZUKI, CHUUDAN MAEGERI, CHUUDAN YOKOGERI KEKOMI right)',
    },

    {
        id: 1,
        title: '1st Kyu',
        beltColour: 'brown',
        beltTitle: 'Brown Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'SANBON RENZUKI (step in)',
            'JOUDAN AGEUKE, GYAKU ZUKI (step back)',
            'CHUUDAN SOTOUKE, YOKO ENPI, YOKO URAKEN UCHI (ZENKUTSU DACHI changing stance to KIBA DACHI) (step in)',
            'CHUUDAN UCHIUKE, GYAKU ZUKI (step back)',
            'KOUKUTSU SHUTOU UKE, ZENKUTSU NUKITE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'RENGERI (GEDAN KAKIWAKE CHUUDAN, JOUDAN) (step in)',
            'MAWASHIGERI (step in)',
            'YOKOGERI KEAGE (in KIBA DACHI, right and left) (step in)',
            'YOKOGERI KEKOMI (ZENKUTSUDACHI) (step in)',
        ],
        kata: 'BASSAI DAI, KANKU DAI, ENPI or JION (your choice)',
        kumite: 'JIYU IPPON KUMITE (JOUDAN JUNZUKI, CHUUDAN JUNZUKI, CHUUDAN MAEGERI,CHUUDAN YOKOGERI KEKOMI, JOUDAN MAWASHIGERI) right',
    },

    {
        id: 0,
        title: '1st Dan',
        beltColour: 'black',
        beltTitle: 'Black Belt',
        kihonTitle: 'IDOU KIHON Moving basics',
        kihonList: [
            'CHUUDAN JUNZUKI (step in)',
            'SANBON RENZUKI (step in)',
            'JOUDAN AGEUKE, GYAKUZUKI (step back)',
            'CHUUDAN SOTOUKE, YOKOENPI, YOKOURA KENUCHI, GYAKUZUKI (ZENKUTSU DACHI changing stance to KIBA DACHI changing stance to ZENKUTSUDACHI) (step in)',
            'CHUUDAN UCHIUKE, KIZAMI ZUKI, GYAKUZUKI (step back)',
            'KOUKUTSU SHUTOU UKE, ZENKUTSU NUKITE (step in)',
            'MAEGERI (GEDAN KAKIWAKE) (step in)',
            'RENGERI (GEDAN KAKIWAKE CHUUDAN, JOUDAN) (step in)',
            'MAWASHIGERI (step in)',
            'YOKOGERI KEAGE (KIBADACHI – right and left) (step in)',
            'YOKOGERI KEKOMI (ZENKUTSUDACHI)',
        ],
        kata: 'BASSAI DAI, KANKU DAI, ENPI or JION (your choice)',
        kumite: 'JIYU IPPON KUMITE (right JOUDAN JUNZUKI, right CHUUDAN JUNZUKI, right CHUUDAN MAEGERI, right CHUUDAN YOKOGERI KEKOMI, right CHUUDAN MAWASHI GERI – one side only) ',
    },
]
