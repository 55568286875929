// React
import React from 'react'

// Components
import { Dropdown, DropdownButton } from 'react-bootstrap'

// Styles
import '../styles/pageNav.css'

export default function PageNav({ pages }) {
    let menuCounter = 0
    return (
        <div className='page-nav'>
            <DropdownButton id='dropdown-basic-button' title='Jump to...'>
                {pages.map((s) => {
                    return (
                        <Dropdown.Item
                            key={menuCounter++}
                            href={'#' + s.replace(/ /g, '')}
                        >
                            {s}
                        </Dropdown.Item>
                    )
                })}
            </DropdownButton>
        </div>
    )
}

export function PageLocation({ identifier }) {
    return (
        <a
            name={identifier.replace(/ /g, '')}
            href={'#' + identifier.replace(/ /g, '')}
            style={{
                height: '0',
                fontSize: '0',
                margin: '0',
                padding: '0',
                textIndent: '100%',
                position: 'absolute',
            }}
        >
            {identifier}
        </a>
    )
}
