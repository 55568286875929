// React
import React from 'react'

// Components
import { Quote } from 'react-bootstrap-icons'

// Styles
import '../styles/quoteDisplay.css'

export default function QuoteDisplay({ quoteMessage, author, image }) {
    return (
        <div className='quote-display'>
            <div className=' card mb-3'>
                <div className='row g-0'>
                    <div className='col-4' style={{ display: 'flex' }}>
                        <img
                            src={image}
                            className='img-fluid rounded-start normal-image'
                            alt={author}
                        />
                    </div>
                    <div className='col-8'>
                        <div className='mini-image'>
                            <img src={image} alt={author} />
                        </div>
                        <div className='card-body'>
                            <h5 className='card-title'>
                                <Quote />
                            </h5>
                            <p className='card-text'>
                                <q>{quoteMessage}</q>
                            </p>
                            <p className='card-text'>
                                <small className='text-muted'>{author}</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
