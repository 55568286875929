// React
import React from 'react'

// Components
import { Card } from 'react-bootstrap'
import { PageLocation } from '../../shared/PageNav'
import PageBanner from '../../shared/PageBanner'

// List
import { dojoKunList } from '../../lists/dojoKun.js'
import { preceptList } from '../../lists/precepts.js'

// Styles
import '../../styles/members/philosophy.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function Philosophy() {
    const PreceptList = ({ precepts }) => {
        return (
            <ol className='precepts'>
                {precepts.map((s, i) => {
                    return <li key={i}>{s}</li>
                })}
            </ol>
        )
    }

    const DojoKunItem = ({ id, audio, kanji, japanese, english }) => {
        return (
            <Card key={id} className='dojo-group grey-out'>
                <Card.Body>
                    <Card.Title className='dojo-title'>{kanji}</Card.Title>
                    <Card.Subtitle className='mb-2 text-muted'>
                        {japanese}
                    </Card.Subtitle>
                    <div>
                        <audio controls>
                            <source src={audio} type='audio/mpeg' />
                            Your browser does not support the audio element.
                        </audio>
                        <div className='dojo-english'>{english}</div>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    const DojoKunList = ({ dojoKuns }) => {
        return (
            <div className='text-center'>
                {dojoKuns.map((s) => {
                    return (
                        <DojoKunItem
                            id={s.id}
                            key={s.id}
                            audio={s.audio}
                            kanji={s.kanji}
                            japanese={s.japanese}
                            english={s.english}
                        />
                    )
                })}
            </div>
        )
    }

    const BushidoBody = () => {
        return (
            <>
                <h2>Bushido</h2>
                <p>
                    Bushido has been the samurai code of conduct in Japan for
                    centuries. Based firmly on the teachings of Zen, Bushido was
                    intended to help the samurai master their nature and
                    understand their minds and the universe through direct
                    experience—as well as through fostering strength,
                    self-control and wisdom. Bushido is based on seven essential
                    principles:
                </p>
                <ol>
                    <li> Seigi seigi : The right decision and rectitude</li>
                    <li> Yuki yuki : Bravery and heroism</li>
                    <li> Jin jin : Compassion and benevolence to all</li>
                    <li> Reigi reigi : Courtesy and right action</li>
                    <li> Makoto makoto : Truthfulness and utter sincerity</li>
                    <li> Meiyo meiyo : Honor and glory</li>
                    <li> Chugi chugi : Devotion and loyalty </li>
                </ol>
                <p>
                    Martial spirit and courage were, of course, essential
                    aspects of Bushido. But for the samurai, Bushido‘s highest
                    goal was complete virtue in thought and action. Each samurai
                    followed a carefully-designed regimen of polite ceremony and
                    etiquette intended to promote such virtue. With its emphasis
                    on prescribed form, Bushido helped the samurai harmonize
                    mind with body, enabling them to maintain a certain
                    calmness, or heijoshin (literally, “ordinary everyday
                    mind”), even in the face of hardship. Sincerity, kindness,
                    honesty, filial piety and honor all formed part of the core
                    of Bushido. And they were the seed from which the karate
                    tradition grew. These attributes, and the wisdom,
                    understanding and peaceful strength they promote, are some
                    of karate’s greatest benefits. They are also among Japan’s
                    greatest gifts to the world.
                </p>{' '}
            </>
        )
    }

    return (
        <div className='white-out'>
            <PageLocation identifier={'Philosophy'} />
            <PageBanner
                image={'assets/members.png'}
                text='Philosophy'
            ></PageBanner>
            <div>
                <div className='px-4 body-text'>
                    <PageLocation identifier={'Dojo-kun'} />
                    <h2>The Dojo Kun</h2>
                    <p>
                        Senior instructors at the JKA developed the Dojo Kun,
                        which everyone studying at the JKA commits to memory.
                        With each practice session at the dojo, students kneel
                        in the seiza position and repeat these five precepts out
                        loud. This process reminds students of the right
                        attitude, frame of mind and virtues to strive for both
                        within the dojo, and outside.
                    </p>
                    <DojoKunList dojoKuns={dojoKunList}></DojoKunList>
                </div>

                <div className='px-4 body-text'>
                    <PageLocation identifier={'Precepts'} />
                    <h2>The Twenty Precepts of Karate</h2>
                    <p>
                        Before he established the JKA, Master Funakoshi Gichin
                        laid out the Twenty Precepts of Karate, which form the
                        foundations of the art. Within these twenty principles,
                        based heavily on Bushido and Zen, lies the philosophy of
                        the JKA.
                    </p>
                    <PreceptList precepts={preceptList}></PreceptList>
                </div>

                <div className='px-4 pb-3 body-text'>
                    <PageLocation identifier={'Bushido'} />
                    <BushidoBody />
                </div>
            </div>
        </div>
    )
}
