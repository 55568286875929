// React
import React, { useEffect } from 'react'

// Components
import PageBanner from '../shared/PageBanner'

// Lists
// import { instructorImagesList } from '../lists/images'
import { instructorsList } from '../lists/instructors'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/instructor.css'

export default function Instructor() {
    useEffect(() => {
        document.title =
            'Instructor - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    const TopBanner = () => {
        return (
            <PageBanner
                text={
                    <>
                        <h1>Our Instructors</h1>
                    </>
                }
                image={'assets/instructor.png'}
            ></PageBanner>
        )
    }

    // const MarqueeImageItems = () => {
    //     return instructorImagesList.map((s, i) => {
    //         return <img key={i} src={s} alt='' title='Instructor Image'></img>
    //     })
    // }

    const Body = () => {
        return (
            <>
                <div className='px-4 pb-2 body-text'>
                    <Qualifications />
                </div>
                <div className='text-center'>
                    <Instructors />
                </div>
            </>
        )
    }

    const Instructors = () => {
        return (
            <div className='grey-out'>
                {instructorsList.map((s, i) => {
                    return (
                        <div
                            className='container'
                            key={i}
                            style={{ width: '100%' }}
                        >
                            <a
                                name={s.name.replace(' ', '-')}
                                href={'#' + s.name.replace(' ', '-')}
                                style={{
                                    position: 'absolute',
                                    height: 0,
                                    fontSize: 0,
                                }}
                            >
                                {s.name}
                            </a>
                            <div className='row'>
                                <div className='d-none d-sm-block col-sm-4 ins-side-banner'>
                                    {/* <img src={s.imagebig} /> */}

                                    <video autoPlay muted playsInline loop>
                                        <source
                                            src={s.video}
                                            type='video/mp4'
                                        />
                                    </video>
                                </div>
                                <div className=' col-sm-8'>
                                    <div className='name-group'>
                                        <img
                                            src={s.imagesmall}
                                            className='ins-image-small mt-3 mb-3'
                                            alt={s.name.replace(' ', '-')}
                                        />

                                        <h2>{s.name}</h2>
                                        <div className='ins-subhead'>
                                            {s.subHead}
                                        </div>
                                        <div className='d-flex flex-row justify-content-center'>
                                            <AttributesItem
                                                attri={s.attributes}
                                            ></AttributesItem>
                                        </div>
                                    </div>
                                    <ProfileParagraphs prf={s.profile} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const ProfileParagraphs = ({ prf }) => {
        return prf.map((s, i) => {
            return <p key={i}>{s}</p>
        })
    }

    const AttributesItem = ({ attri }) => {
        return attri.map((s, i) => {
            return (
                <div key={i} className='p-2 ins-creds rounded'>
                    {s}
                </div>
            )
        })
    }

    const Qualifications = () => {
        return (
            <>
                <h2>JKA Instructor Qualification</h2>
                <p>
                    At JKA headquarters, all our instructors are specially
                    trained and licensed to teach. Each of them must train
                    extensively and complete the JKA’s unique specialist
                    instructor training program before receiving certification
                    to fulfil the various functions they are responsible for
                    within the JKA.
                </p>
                <p>
                    The JKA offers three basic kinds of qualifications:
                    authorized Instructor qualifications (for teaching karate);
                    authorized Judge qualifications (for judging and refereeing
                    at tournaments); and authorized Examiner qualifications (for
                    testing and evaluating Dan, instructors and other types of
                    qualification). Typically, it is only those who are already
                    qualified instructors who are eligible for the other two
                    types.
                </p>
                <p>
                    For each type of official qualification, there are four
                    classes: D through A. Each subsequent class authorizes the
                    instructor to carry out an increasingly broad spectrum of
                    activities appropriate to the individual’s experience—with
                    Instructors and Judges outside Japan also carrying out their
                    activities according to the rules for their country. Class A
                    is the highest rank offered in the JKA.
                </p>
            </>
        )
    }

    return (
        <>
            <TopBanner />

            <Body />
        </>
    )
}
