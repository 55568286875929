// React
import React from 'react'

// Components
import { PageLocation } from '../../shared/PageNav'
import { ListGroup, Tab, Row, Col } from 'react-bootstrap'
import PageBanner from '../../shared/PageBanner'

// List
import { syllabusList } from '../../lists/syllabus'

// Styles
import '../../styles/syllabus.css'

export default function Syllabus() {
    const TabsTitleList = ({ syllabusses }) => {
        return syllabusses.map((s) => {
            return (
                <ListGroup.Item
                    key={s.id + 10}
                    action
                    href={'#syllabus-' + s.id}
                >
                    <div className={'colour-code ' + s.beltColour}></div>{' '}
                    {s.title}
                </ListGroup.Item>
            )
        })
    }

    const TabsPanesList = ({ syllabusses }) => {
        return syllabusses.map((s) => {
            return (
                <Tab.Pane key={s.id + 1001} eventKey={'#syllabus-' + s.id}>
                    <div className='p-3'>
                        <h2>{s.title}</h2>
                        <div>
                            <div
                                className={'colour-code ' + s.beltColour}
                            ></div>
                            {s.beltTitle}
                        </div>
                        <div>
                            <span className='section'>Kihon</span>
                            {s.kihonTitle}
                            {s.kihonList.map((t) => {
                                return <div>{t}</div>
                            })}

                            {s.kata === undefined ? (
                                <></>
                            ) : (
                                <>
                                    <span className='section'>Kata</span>
                                    {s.kata}
                                </>
                            )}

                            {s.kumite === undefined ? (
                                <></>
                            ) : (
                                <>
                                    <br></br>
                                    <span className='section'>Kumite</span>
                                    {s.kumite}
                                </>
                            )}
                        </div>
                    </div>
                </Tab.Pane>
            )
        })
    }

    const KyuSyllabusList = () => {
        return (
            <Tab.Container
                id='list-group-tabs-example'
                defaultActiveKey={'#syllabus-10'}
            >
                <Row className='list-group-background'>
                    <Col sm={3} className='list-group-background p-0'>
                        <ListGroup>
                            <TabsTitleList
                                syllabusses={syllabusList}
                            ></TabsTitleList>
                        </ListGroup>
                    </Col>
                    <Col sm={9} className='p-0'>
                        <Tab.Content>
                            <TabsPanesList
                                syllabusses={syllabusList}
                            ></TabsPanesList>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }

    return (
        <>
            <PageLocation identifier={'Syllabus'} />
            <PageBanner
                image={'assets/members.png'}
                text='Syllabus'
            ></PageBanner>
            <div className='grey-out'>
                <div className='p-3 body-text grey-out'>
                    <KyuSyllabusList />
                </div>
            </div>
        </>
    )
}
