// React
import { React, useEffect } from 'react'
import { Button } from 'react-bootstrap'
// Components
import QuoteDisplay from '../shared/QuoteDisplay'
import { ArrowRight } from 'react-bootstrap-icons'
// Lists
import { benefitList } from '../lists/benefits'
import { easySocialsList } from '../lists/contactMethods'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/home.css'

export default function Home() {
    useEffect(() => {
        document.title =
            'Home - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    const CardItemAge = ({ image, title, text }) => {
        return (
            <div className='card glowcard card-age'>
                <div className='card-body'>
                    <div
                        className='card-img-container'
                        style={{ overflow: 'hidden' }}
                    >
                        <img
                            src={image}
                            alt={title}
                            className='card-img fader'
                        />
                    </div>

                    <h6 className='card-subtitle  text-muted overlay-title'>
                        {title}
                    </h6>

                    <p className='card-text'>{text}</p>
                </div>
            </div>
        )
    }

    const CardItemGeneral = ({ icon, title, text }) => {
        return (
            <div className='card glowcard card-gen'>
                <div className='card-body'>
                    <h5 className='card-title'>{icon}</h5>
                    <h6 className='card-subtitle mb-2 text-muted'>{title}</h6>
                </div>
                <p className='card-text'>{text}</p>
            </div>
        )
    }

    const CardItemList = ({ benefits, isGeneral }) => {
        return benefits.map((s) => {
            return isGeneral ? (
                <CardItemGeneral
                    key={s.id}
                    image={s.image}
                    icon={s.icon}
                    title={s.title}
                    text={s.text}
                />
            ) : (
                <CardItemAge
                    key={s.id}
                    image={s.image}
                    icon={s.icon}
                    title={s.title}
                    text={s.text}
                />
            )
        })
    }

    const TopBanner = () => {
        return (
            <div className='home-video-container'>
                <video autoPlay muted playsInline loop className='header-video'>
                    <source src='assets/jkaa.mp4' type='video/mp4' />
                </video>
            </div>
        )
    }

    const Body = () => {
        return (
            <div>
                <QuoteDisplay
                    quoteMessage={
                        'Karate is a very frustrating path, you will never perfect it. But we strive for perfection which you can never achieve, therefore you have to keep trying and trying.'
                    }
                    author={'Nishimura Takaatsu'}
                    image={'assets/nishimura-q.png'}
                />

                <p>
                    We are a <b>local non-profit </b> martial arts school based
                    in Melbourne. In JKA Melbourne, you can learn the
                    traditional art of Shotokan Karate where we move forward
                    with the high standards set by Nishimura Takaatsu Shihan.
                    Allow us to empower and bring out the best version of you.
                    Visit our dojo and train with us. Your{' '}
                    <b>first 2 sessions are free</b> to get a feel of what it's
                    like. There is no lock-in contract and no complicated fee
                    structure.
                </p>

                {/* <p>
                    We cater to families and all age groups. It's never too
                    early or too late to start your journey! Whether you're in
                    primary school or a senior looking to better your health, we
                    can help you achieve your goals
                </p> */}

                <div className='benefits age'>
                    <CardItemList
                        benefits={benefitList.filter((s) => s.image !== null)}
                        isGeneral={false}
                    />
                </div>

                <p>
                    JKA Karate can help you in all aspects of life. Like
                    Funakoshi Gichin Shihan said, "
                    <i>
                        Apply the way of karate to all things. Therein lies its
                        beauty
                    </i>
                    ."
                </p>
                <div className='benefits general'>
                    <CardItemList
                        benefits={benefitList.filter((s) => s.image === null)}
                        isGeneral={true}
                    />
                </div>
            </div>
        )
    }

    const JoinSocials = ({ easySocials }) => {
        return easySocials.map((s) => {
            return (
                <div key={s.id} className='tooltip-holder'>
                    <a
                        href={s.href}
                        className={s.className}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {s.icon}
                    </a>
                    <span className='tooltip-text'>{s.label}</span>
                </div>
            )
        })
    }

    const JoinButton = () => {
        return (
            <div className='text-center pb-5 pt-4 join-row'>
                <Button
                    className='join-button'
                    onClick={() => {
                        window.location = '/join'
                    }}
                >
                    Join Now <ArrowRight />
                </Button>
                <div className='join-socials'>
                    <JoinSocials easySocials={easySocialsList} />
                </div>
            </div>
        )
    }

    return (
        <>
            <TopBanner />
            <header className='h1-container'>
                <div>
                    <h1 className='invert'>Japan Karate Association</h1>
                    <div className='sub-head'>Melbourne</div>
                    <JoinButton />
                </div>
            </header>

            <div className='px-4 pb-4 body-text main-padding'>
                <p className='pb-3'>
                    <b className='highlight'>
                        We are JKA Australia: Melbourne - Thornbury branch
                    </b>{' '}
                    / A member of the{' '}
                    <a
                        href='https://www.jkaaustralia.com.au/'
                        target={'_blank'}
                        rel='noreferrer'
                        className='p-link'
                    >
                        Japan Karate Association Australia
                    </a>
                    . The legacy of Nishimura Takaatsu lives on. We are
                    recognised and authorised by the{' '}
                    <a
                        href='https://www.jka.or.jp/'
                        target={'_blank'}
                        rel='noreferrer'
                        className='p-link'
                    >
                        {' '}
                        Japan Karate Association
                    </a>{' '}
                    in Japan to teach traditional Karate as it was passed down
                    by the masters of old.
                </p>

                <Body />
            </div>
        </>
    )
}
