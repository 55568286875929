// React
import React, { useEffect } from 'react'
// Components
import PageBanner from '../shared/PageBanner'
// Lists
import { trainingTypesList } from '../lists/trainingTypes.js'
// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/beginners.css'

export default function Beginners() {
    useEffect(() => {
        document.title =
            'Beginners - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    const TopBanner = () => {
        return (
            <PageBanner
                text={
                    <>
                        <h1>Beginners</h1>
                    </>
                }
                image={'assets/beginners.png'}
            ></PageBanner>
        )
    }

    const TrainingTypeItem = ({ name, description, image }) => {
        return (
            <div className='card bg-dark text-white'>
                <img className='card-img' src={image} alt='Kata' />
                <div className='card-img-overlay'>
                    <div className='card-title'>{name}</div>
                    <div className='card-text train-type-desc'>
                        {description}
                    </div>
                </div>
            </div>
        )
    }

    const TrainingTypesList = ({ trainingTypesList }) => {
        return trainingTypesList.map((s) => {
            return (
                <TrainingTypeItem
                    key={s.id}
                    name={s.name}
                    description={s.description}
                    image={s.image}
                ></TrainingTypeItem>
            )
        })
    }

    const Body = () => {
        return (
            <>
                <h2>What is Karate?</h2>
                <p>
                    Karate means ‘Empty hand’ and is a traditional Japanese
                    system of self-defence that employs blocks, punches, kicks,
                    strikes, body evasion and occasional throws.
                </p>
                <h2>What is Shotokan?</h2>
                <p>
                    Shotokan is the name of the style or system of karate
                    practiced by JKA, it literally means ‘House of Shoto’ which
                    was our founder, Funakoshi Gichin Shihan’s calligraphy pen
                    name and became the name of the first official dojo in Tokyo
                    in 1939.
                </p>
                <h2>The Dojo Kun</h2>
                <p>
                    The “Dojo Kun” was developed by senior JKA instructors. It
                    is a set of five guiding ethical principles which students
                    recite out loud at the end of every training class. This
                    aims to remind students of the right attitude, frame of mind
                    and virtues to strive for both, within the dojo and outside.
                </p>
                <h2>Training with us</h2>
                <p>
                    JKA Karate classes or training sessions, can be broken into
                    3 key areas of practice: Kihon, Kata and Kumite.
                </p>
                <div className='practice'>
                    <TrainingTypesList trainingTypesList={trainingTypesList} />
                </div>
            </>
        )
    }

    return (
        <>
            <TopBanner />
            <div className='px-4 pb-5 body-text'>
                <Body />
            </div>
        </>
    )
}
