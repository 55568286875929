// React
import React, { useEffect } from 'react'
// Components
import PageBanner from '../shared/PageBanner'
// Styles
import '../styles/aboutJka.css'

export default function AboutJka() {
    useEffect(() => {
        document.title =
            'About JKA - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    const TopBanner = () => {
        return (
            <PageBanner
                text={<h1>About JKA</h1>}
                image={'assets/instructors.png'}
            ></PageBanner>
        )
    }

    const KeyIndividuals = () => {
        return (
            <div className='container about-container'>
                <div className='row'>
                    <div className='col-6 col-sm-4 col-md-2'>
                        <img
                            src={'assets/funakoshi-i.png'}
                            className='img-fluid rounded'
                            alt='Gichin Funakoshi'
                        ></img>
                        <div className='sub-text'>
                            Funakoshi Gichin Shihan was the founder of Shotokan
                            karate-do, perhaps the most widely known style of
                            karate, and is known as a "father of modern karate"
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md-2'>
                        <img
                            src={'assets/ueki-i.png'}
                            className='img-fluid rounded'
                            alt='Ueki Masaaki'
                        ></img>
                        <div className='sub-text'>
                            Ueki Masaaki Shihan who succeeded the second chief
                            instructor Sugiura, and inaugurated as the third
                            chief instructor.{' '}
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md-2'>
                        <img
                            src={'assets/nishimura-i.png'}
                            className='img-fluid rounded'
                            alt='Nishimura Takaatsu'
                        ></img>
                        <div className='sub-text '>
                            Nishimura Takaatsu Shihan is the founder and former
                            Chief Instructor of JKA Australia.
                        </div>
                    </div>

                    <div className='col-6 col-sm-4 col-md-2'>
                        <img
                            src={'assets/phil-i.png'}
                            className='img-fluid rounded'
                            alt='Phil Young'
                        ></img>
                        <div className='sub-text'>
                            Phil Young Sensei is the current Chief Instructor of
                            JKA Australia
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md-2'>
                        <img
                            src={'assets/beverly-i.png'}
                            className='img-fluid rounded'
                            alt='Beverly Cook'
                        ></img>
                        <div className='sub-text'>
                            Beverly Cook Sensei is the President of JKA
                            Australia
                        </div>
                    </div>
                    <div className='col-6 col-sm-4 col-md-2'>
                        <img
                            src={'assets/ciaran-i.png'}
                            className='img-fluid rounded'
                            alt='Ciaran Horgan'
                        ></img>
                        <div className='sub-text'>
                            Ciaran Horgan Sensei is the Branch Head and Head
                            Instructor of JKA Australia Melbourne Thornbury
                            Branch
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const Body = () => {
        return (
            <>
                <h2>The Japan Karate Association</h2>
                <p>
                    The{' '}
                    <a
                        href='https://www.jka.or.jp/'
                        target={'_blank'}
                        rel='noreferrer'
                        className='p-link'
                    >
                        Japan Karate Association (“JKA”)
                    </a>{' '}
                    is the world’s largest and most prestigious karate
                    organization, and is the preserver of the soul and spirit of
                    the art of “kokufu-bunka karate-do” karate based on the
                    ancient Japanese tradition of Bushido (the way of the
                    samurai). The great masters of JKA paved the way to today’s
                    karate.
                </p>
                <h2>Japan Karate Association Australia</h2>
                <p>
                    The{' '}
                    <a
                        href='https://www.jkaaustralia.com.au/'
                        target={'_blank'}
                        rel='noreferrer'
                        className='p-link'
                    >
                        Japan Karate Association (JKA) Australia Inc. (“JKA
                        Australia”)
                    </a>{' '}
                    was incorporated in 2010 and is one of the leading JKA
                    karate organisations in Australia. We have hundreds of
                    members practicing at our branches or ‘dojos’, which are
                    located at various locations in Victoria, New South Wales,
                    Queensland and South Australia. JKA Australia teaches
                    traditional JKA Shotokan-style karate. The standards taught
                    are of the highest level in keeping with the technical
                    requirements set by JKA Headquarters.
                </p>
                <p>
                    The purpose of JKA Australia is to develop and promote JKA
                    Shotokan karate in Australia. Our instructors are certified
                    by JKA Headquarters based in Tokyo, Japan. The standards
                    taught are of the highest level in keeping with the
                    technical requirements set by JKA HQ. Our goal is to
                    maintain these standards, to grow and to help all our
                    students achieve their personal goals and aspirations.
                </p>
                <h2>Key Individuals of JKA</h2>
                Here are the masters and teachers of JKA Australia as well as
                the JKA in Japan HQ that we are a member of
                <KeyIndividuals />
            </>
        )
    }

    return (
        <>
            <TopBanner />
            <div className='px-4 pb-4 body-text'>
                <Body />
            </div>
        </>
    )
}
