export const dojoKunList = [
    {
        id: 1,
        kanji: '一、人格完成に努むること',
        japanese: '(Hitotsu) Jinkaku kansei ni tsutomuru koto',
        english: 'Seek Perfection of Character',
        audio: 'assets/audio/Jinkaku.mp3',
    },

    {
        id: 2,
        kanji: '一、誠の道を守ること',
        japanese: '(Hitotsu) Makoto no michi o mamoru koto',
        english: 'Be Sincere',
        audio: 'assets/audio/Makoto.mp3',
    },
    {
        id: 3,
        kanji: '一、努力の精神を養うこと',
        japanese: '(Hitotsu) Doryoku no seishin o yashinau koto',
        english: 'Put maximum effort into everything you do',
        audio: 'assets/audio/Doryoku.mp3',
    },
    {
        id: 4,
        kanji: '一、礼儀を重んずること',
        japanese: '(Hitotsu) Reigi o omonzuru koto',
        english: 'Respect Others',
        audio: 'assets/audio/Reigi.mp3',
    },
    {
        id: 5,
        kanji: '一、血気の勇を戒むること',
        japanese: '(Hitotsu) Kekki no yuu o imashimuru koto',
        english: 'Develop Self Control',
        audio: 'assets/audio/Kekki.mp3',
    },
]
