import {
    Heart,
    Bicycle,
    Lightning,
    Globe,
    Bookmark,
    Flag,
    Check,
    Cloud,
} from 'react-bootstrap-icons'

export const benefitList = [
    {
        id: 1,
        icon: <Heart />,
        image: null,
        title: 'Character Development',
        text: 'Karate teaches about the importance of being humble and persevering to achieve your goals.',
    },
    {
        id: 2,
        icon: <Bicycle />,
        image: null,
        title: 'Health and Fitness',
        text: "Martial arts truly provide a full body workout. The consistent practice has shown to increase overall mobility, improve your body's pressure response, and increase muscle.",
    },
    {
        id: 3,
        icon: <Lightning />,
        image: null,
        title: 'Self-Defence',
        text: 'If you get attacked on the street or somewhere else by a robber or a similar threat, you’ll have a much higher chance of defending yourself effectively without taking damage.',
    },
    {
        id: 4,
        icon: <Check />,
        image: null,
        title: 'Builds Confidence',
        text: 'Practicing, improving, and succeeding at a skill improves self-image and gives participants confidence that they can succeed in other areas and ventures. ',
    },
    {
        id: 5,
        icon: <Flag />,
        image: null,
        title: 'Leadership Skills',
        text: 'Students who have confidence in themselves are more likely to become successful leaders.',
    },
    {
        id: 6,
        icon: <Cloud />,
        image: null,
        title: 'Helps you Focus',
        text: 'You’ll learn how to keep your head in the game without drifting away and how to perform with focus even in the most stressful situations.',
    },
    {
        id: 7,
        icon: <Globe />,
        image: null,
        title: 'Internationally Recognised',
        text: 'Local students will receive official recognition from JKA Headquarters in Tokyo, Japan as they progress in their studies and training in the Art of Shotokan Karate.',
    },
    {
        id: 8,
        icon: <Bookmark />,
        image: null,
        title: 'Access to World-Class Seminars',
        text: 'As members of JKA, students have an opportunity to attend seminars hosted by the Australian leaders as well as by masters from Japan HQ.',
    },
    {
        id: 9,
        icon: null,
        image: 'assets/adults.png',
        title: 'Adults',
        text: 'Karate gives adults a purpose: something to strive towards. It helps them to become calmer when dealing with stressful situations.',
    },
    {
        id: 10,
        icon: null,
        image: 'assets/teens.png',
        title: 'Teens',
        text: 'Karate teaches respect and helps teens build confidence. It also gives them the tools that they need to defend themselves against bullies ',
    },
]
