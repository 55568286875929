// React
import React from 'react'

// Components
import { Accordion } from 'react-bootstrap'
import { PageLocation } from '../../shared/PageNav'
import VideoModal from '../../shared/VideoModal'
import PageBanner from '../../shared/PageBanner'

// List
import { faqsList } from '../../lists/faqs'
import { videosList } from '../../lists/videos'

// Styles
import '../../styles/members/faqs.css'

export default function Faqs() {
    const FaqAccordionItem = ({ id, title, content }) => {
        return (
            <Accordion.Item eventKey={id}>
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body>{content}</Accordion.Body>
            </Accordion.Item>
        )
    }

    const FaqAccordionItemsList = ({ faqsList }) => {
        return faqsList.map((s) => {
            return (
                <FaqAccordionItem
                    key={s.id}
                    id={s.id}
                    title={s.title}
                    content={s.content}
                />
            )
        })
    }

    const Faqs = () => {
        return (
            <div>
                <div className='py-3'>
                    <Accordion defaultActiveKey='0' flush>
                        <FaqAccordionItemsList
                            faqsList={faqsList}
                        ></FaqAccordionItemsList>
                    </Accordion>
                </div>
            </div>
        )
    }

    const VideoItem = ({ title, url }) => {
        return (
            <div className='card'>
                <VideoModal url={url} title={title}></VideoModal>
                <div className='card-body'>
                    <div className='card-text'>{title}</div>
                </div>
            </div>
        )
    }

    const VideoItemsList = ({ videoItemsList }) => {
        return videoItemsList.map((s) => {
            return <VideoItem key={s.id} title={s.title} url={s.url} />
        })
    }

    const Videos = () => {
        return (
            <>
                <div className='videos text-center'>
                    <VideoItemsList videoItemsList={videosList} />
                </div>
            </>
        )
    }

    const Body = () => {
        return (
            <>
                <div className='p-3 body-text'>
                    <Faqs />
                </div>
                <div className='p-3'>
                    <Videos />
                </div>
            </>
        )
    }

    return (
        <div className='white-out'>
            <PageLocation identifier={'FAQs'} />
            <PageBanner image={'assets/members.png'} text='FAQs'></PageBanner>
            <Body />
        </div>
    )
}
