// Styles
import '../styles/pageBanner.css'

export default function PageBanner({ text, image }) {
    var parallaxStyle = {
        backgroundImage: 'url(' + image + ')',

        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    var titleStyle = {
        width: '100%',
        textAlign: 'center',
    }

    return (
        <>
            {' '}
            <div className='banner-title' style={titleStyle}>
                {text}
            </div>
            <div className='parallax' style={parallaxStyle}>
                {text === undefined ? <></> : <></>}
            </div>
        </>
    )
}
