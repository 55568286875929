import React, { useEffect } from 'react'
import { Robot } from 'react-bootstrap-icons'
export default function NotFound() {
    useEffect(() => {
        document.title =
            'Not Found - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    return (
        <div className='text-center   '>
            <div className='giant'>404</div>
            <p className='px-5 text-center'>
                Beep. Boop. Bleep. We can't seem to find the page you are
                looking for. <br /> You can either go back to the{' '}
                <a href='/' className='p-link'>
                    Home page
                </a>{' '}
                or choose one of the paths from the menu at the top.
            </p>
            <p className='text-center' style={{ fontSize: '5rem' }}>
                <Robot />
            </p>
        </div>
    )
}
