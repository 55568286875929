export const preceptList = [
    'Karate-do begins and ends with respect.',
    'In Karate-do there is no first strike.',
    'Karate stands on the side of justice.',
    'First know yourself before attempting to know others.',
    'Spirit before technique.',
    'Always be ready to release your mind.',
    'Accidents arise from negligence.',
    'Do not think that Karate training is only in the dojo.',
    'It will take your entire life to learn Karate; there is no limit.',
    'Put your everyday living into Karate and you will find “Myo” (subtle secrets).',
    'Karate is like boiling water.  If you do not heat it constantly it will cool.',
    'Do not think that you have to win.  Rather think that you do not have to lose.',
    'Victory depends on your ability to distinguish vulnerable points from invulnerable ones.',
    'The battle is according to how you move guarded and unguarded (move according to your opponent).',
    'Think your hands and feet as swords.',
    'When you leave home, think that you have numerous opponents waiting for you. It is your behaviour that invites trouble.',
    'Beginners must master low stance and posture.  Natural body positions are for the advanced.',
    'Practicing a kata is one thing; engaging in a real fight is another.',
    'Do not forget to correctly apply: strength and weakness of power, stretching and contraction of the body, and slowness and speed of techniques.',
    'Always think and devise ways to live the precepts every day.',
]
