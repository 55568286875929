// React
import React from 'react'

// Lists
import { footerItems } from '../lists/contactMethods.js'

export default function Footer() {
    return (
        <>
            <div className='p-3 footer-body'>
                <div className='p-2'>
                    <img
                        src='../assets/jka-header.svg'
                        alt='JKA Karate Melbourne'
                        style={{
                            width: '8rem',
                            // marginLeft: '-0.3rem',
                            // marginBottom: '0rem',
                        }}
                    />
                </div>

                {footerItems.map((s) => {
                    return (
                        <div
                            key={s.categoryId}
                            className='p-2 footer-category-group'
                        >
                            <div className='footer-category-title d-inline-block py-2  '>
                                {s.category}
                            </div>
                            <div>
                                {s.items.map((t) => {
                                    return (
                                        <a
                                            key={t.id}
                                            target='_blank'
                                            href={t.href}
                                            rel='noreferrer'
                                            className='d-block'
                                        >
                                            {t.icon} {'  ' + t.label}
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
