// React
import React from 'react'

// Components
import { PageLocation } from '../../shared/PageNav'
import PageBanner from '../../shared/PageBanner'
import ReadMore from '../../shared/ReadMore'

// Styles
import '../../styles/members/history.css'

export default function History() {
    const Body = () => {
        return (
            <>
                <h2>A Brief History of the Japan Karate Association</h2>
                <p>
                    Originally, the martial art Te (“Hand”) developed in Okinawa
                    as a system of self-defense. Due to Okinawa’s frequent
                    contact and exchange with China, it is certain that the
                    Okinawan martial art was influenced by Chinese kempo at some
                    point during its development. However, with only oral
                    tradition and no formal contemporary written records, it is
                    not certain exactly when the art called Kara-Te first
                    emerged in Okinawa. It is believed that it developed roughly
                    500 years ago, when the dynastic ruler King Shoha unified
                    the region after decades of warfare and issued an edict
                    banning the possession of weapons on the island.
                </p>
                <p>
                    According to conventional accounts, a similar law forbidding
                    the possession or use of weapons was re-issued and enforced
                    by the Satsuma clan, who had invaded Okinawa in the early
                    1600’s and brought it under the rule of the Japanese
                    Shogunate. It is believed that in this environment karate
                    developed as a form of unarmed combat for protecting oneself
                    and one’s country, and it was taught and practiced in
                    secret.
                </p>
                <p>
                    Then came the birth in 1868 of Okinawan karate master
                    Funakoshi Gichin. He dedicated his whole life to promoting
                    the values of the art, and introduced the way of
                    karate-jutsu to Japan, where it spread across the country.
                    By 1949, his followers had established an association for
                    the promotion of karate; they called it Nihon Karate Kyokai,
                    or Japan Karate Association. It was the beginning of the
                    JKA…
                </p>
                <h2>The Early Years (1948-1957)</h2>
                <p>
                    The JKA was founded in November, 1948. By 1955, the first
                    headquarters dojo had been built at Yotsuya in Tokyo, and
                    the first JKA Chairman had been appointed: Saigo
                    Kichinosuke, member of the upper house of the Japanese Diet
                    and grandson of Saigo Takamori, one of the greatest heroes
                    of Meiji Japan. In 1956, the JKA set up the first-ever
                    karate specialist instructor intern (kenshusei) training
                    program at the headquarters dojo, and accepted its first
                    round of trainees. This was the start of the finest karate
                    instructor training program ever created, a program never
                    matched or even approached by any other karate organization.
                    It is through this program that the JKA has built up its
                    unique cadre of distinguished karate instructors, all
                    full-time salaried professionals— whose numbers are
                    consistently maintained at roughly twenty individuals.
                </p>
                <p>
                    On April 10, 1957, the JKA became a legal entity when
                    Japan’s Ministry of Education (now Ministry of Education,
                    Science, Sports, and Culture) officially recognized the JKA
                    as an association of members for the promotion of karate and
                    the spread and enrichment of actual karate practice. Twelve
                    years later, another karate organization was also given
                    legal status, based not on membership but on contribution by
                    an individual foundation, mainly for the purpose of
                    arranging karate matches.
                </p>
                <p>
                    Roughly two weeks after official status was granted, Supreme
                    Master Funakoshi passed away at the age of 89. After almost
                    a decade of milestones, it was the end of an era. But the
                    real growth of karate was yet to come.
                </p>
                <h2>Growth & Development (1957-1989)</h2>
                <p>Karate’s popularity continued to grow. In October 1957,
                the 1st JKA All Japan Karate Championship was held in Tokyo. At
                this first tournament, the first karate match rules tournament
                in history, the number of divisions was limited to four: men’s
                individual (general) kumite and kata, and men’s group
                (Prefectural) kumite and kata.</p>
                <p>
                    Once this annual tournament system was established, it
                    wasn’t long before there were JKA karate branches in towns,
                    schools and as many as 40 prominent universities in Japan.
                    In 1958, Master Nakayama was appointed Chief Instructor. In
                    1961, His Majesty the Crown Prince of Japan (now His Majesty
                    the Emperor of Japan) attended the 5th JKA All Japan Karate
                    Championship. JKA karate was getting noticed.
                </p>
                <p>
                    Over the years, the tournament divisions expanded to
                    include: a university division (1969), a women’s kata
                    division (1974), a youth division within the general
                    division (1975), an entirely separate youth division for
                    elementary, junior high and high school students, and a
                    women’s kumite division (1985). In addition, the number of
                    participants grew enormously.
                </p>
                <p>
                    During this period, the JKA further developed its karate
                    instruction system, and while greatly expanding its branch
                    dojo throughout Japan, began sending (as early as 1958) some
                    of its most highly-accomplished professional instructors
                    overseas to America, Europe and the Middle East to teach and
                    establish dojo. Karate was becoming a big hit outside Japan
                    too.
                </p>
                <p>
                    In 1975, with karate’s prospective entry into the Olympics
                    in mind, the first JKA-sponsored international tournament,
                    the IAKF (International Amateur Karate Federation) World Cup
                    was held in the U.S.; over the following several years it
                    was held three additional times. However, in order to
                    preserve the true technique and spirit of ippon-shobu
                    (downing the opponent with one blow), the JKA ultimately
                    established a new tournament, the Shoto World Cup Karate
                    Championship Tournament. In 1985, the first international
                    Shoto World Cup was held in Japan, a testimony to how much
                    karate had become an international art.
                </p>
                <p>
                    This period witnessed spectacular development in the art of
                    karate.As a result of continuous training among instructors,
                    the karate techniques were developed into a complete system.
                    For the first time there emerged a clear, scientific, and
                    practical “best” form for each kumite stance, posture and
                    movement. There also emerged a clear delineation between the
                    “correct” and “incorrect” way to execute each stance, punch,
                    kick or technique.
                </p>
                <p>
                    Once these techniques developed, many instructors went
                    overseas to spread the art of karate around the world. The
                    JKA was the first karate organization to set up dojo outside
                    Japan. This is the reason the JKA is so prominent and
                    powerful overseas.
                </p>
                <p>
                    Furthermore, the JKA became the only karate organization to
                    send its qualified full-time instructors to teach karate at
                    universities. In contrast, some other universities simply
                    have one of their old students hold karate classes.
                </p>
                <p>
                    In 1986, Nakahara Nobuyuki, a distinguished business leader
                    and former Tokyo University Karate Club member, was
                    appointed the eighth Chairman of the JKA.
                </p>
                <p>
                    Sadly, as JKA karate was reaching a pinnacle, Master
                    Nakayama passed away, in 1987. He was 74 years old.
                </p>
                <h2>Overcoming Challenges (1990-1999)</h2>
                <p>
                    In 1990, the JKA was rocked by crisis when a faction within
                    the organization seized the JKA’s official status. The group
                    called an extraordinary General Assembly meeting, but did
                    not identify the agenda or state the purpose as legally
                    required. After this unlawful meeting, the faction succeeded
                    in illegally changing the official executive register. Thus
                    began a long period of litigation.
                </p>
                <p>
                    Despite this, the JKA continued to progress. The
                    overwhelming majority of members and instructors remained
                    loyal to the original and true JKA. Then Master Sugiura
                    Motokuni was appointed to the position of Chief Instructor
                    in 1991. Together with Chairman Nakahara, they began
                    initiating a return to the true spirit of karate—just when
                    that spirit was needed most.
                </p>
                <p>
                    In 1994, the JKA published the first in a series of five
                    authoritative text-books on kata. This series quickly became
                    the “bible” of karate kata.
                </p>
                <p>
                    Then came the court rulings. In 1995, as members had long
                    expected, the JKA won the case in Tokyo Local Court. In
                    1998, it won again in Tokyo Higher Court. Then in June 1999,
                    the Japan Supreme Court rejected the appeal of the seceded
                    group, and the case was closed once and for all. As a
                    result, the legal executive register was restored to the
                    state it was in before the faction had so forcefully changed
                    it.
                </p>
                <p>
                    In the end, the JKA had not only weathered the challenge,
                    but had grown stronger because of it. It had gained more
                    internal cohesion and a stronger sense of identity than ever
                    before.
                </p>
                <h2>
                    The JKA Leaps Forward into the 21st Century (2000 to
                    present)
                </h2>
                <p>
                    With its legal status restored by court rulings, the JKA
                    leapt into the 21st Century. It restructured its internal
                    organization, and also reorganized its financial base.
                </p>
                <p>
                    In December 2000, it purchased land and established a brand
                    new headquarters and dojo in the center of Tokyo. This was
                    the first time the JKA had owned its own land and building.
                    The grand opening ceremony was held in May 2001, attended by
                    numerous dignitaries and many members from other karate
                    organizations.
                </p>
                <p>
                    With renewed vigor, the JKA repositioned itself, clearly
                    defining itself as ‘The Keeper of Karate’s Highest
                    Tradition.’ In 2004, it launched a new official website,
                    through which it continues, as always, to promote true
                    karate around the world.
                </p>
                <p>
                    The Japan Karate Association (JKA) was originally
                    established in 1948 as a not-for-profit organization. Based
                    on its significant contribution to society through a number
                    of activities, the Japanese Ministry of Education recognized
                    JKA as SHADAN HOJIN (incorporated association) in 1957.
                    Through continuous contribution and its commitment to
                    society, JKA was further recognized by the government of
                    Japan as KOEKI SHADAN HOJIN (public interest incorporated
                    association) on March 21, 2012. JKA is the only Japanese
                    martial arts associations to date to be given such
                    recognition by its government.
                </p>
                <p>
                    The mandate of JKA is to contribute to world peace through
                    1) conducting research and providing instruction of
                    Karate-Do, 2) influencing children and adolescents to
                    improve their physical and mental health as well as
                    cultivating the spirit of martial arts, and 3) wide
                    distribution of the concept of Japanese martial art which
                    emphasizes manners and respect.
                </p>
                <p>
                    JKA members endeavor to develop their characters and
                    contribute to society on a daily basis, this is the
                    objective of JKA and is the truth value of Karate-Do as a
                    martial art. JKA takes this responsibility seriously and
                    always endeavor to contribute to society through various
                    activities.
                </p>{' '}
            </>
        )
    }

    return (
        <div className='grey-out'>
            <PageLocation identifier={'History'} />
            <PageBanner
                image={'assets/members.png'}
                text='History'
            ></PageBanner>
            <div className='px-4 pb-3 body-text'>
                <ReadMore
                    contents={<Body />}
                    tallCss='read-more-tall'
                    shortCss='read-more-short'
                ></ReadMore>
            </div>
        </div>
    )
}
