// React
import { React, useState, useRef } from 'react'

// Components
import { Button, Modal, Spinner } from 'react-bootstrap'
import { PlayFill } from 'react-bootstrap-icons'

// Styles
import '../styles/videoModal.css'

export default function VideoModal({ url, title }) {
    const [show, setShow] = useState(false)
    const spinner = useRef()

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleOnLoad = () => {
        spinner.current.className = 'd-none'
    }

    return (
        <>
            <Button
                variant='primary'
                onClick={handleShow}
                style={{
                    width: '90%',
                    padding: '0.5rem',
                    margin: '0.5rem',
                }}
                disabled={url === undefined}
            >
                <PlayFill style={{ fontSize: '1.5rem' }} />
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <Spinner
                        animation='border'
                        role='status'
                        ref={spinner}
                        style={{
                            position: 'absolute',
                            marginLeft: '37%',
                            marginTop: '20%',
                        }}
                    >
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>

                    <iframe
                        className='embed-responsive-item'
                        src={url + '&autoplay=1'}
                        allowFullScreen
                        title={title}
                        style={{ width: '90%', height: '50vh' }}
                        onLoad={handleOnLoad}
                    ></iframe>
                </Modal.Body>
            </Modal>
        </>
    )
}
