// React
import React, { useState, useRef } from 'react'

// Styles
import '../styles/readMore.css'

export default function ReadMore({ contents, shortCss, tallCss }) {
    const [expanded, setExpanded] = useState(false)
    const moreButton = useRef()

    const handleOnClick = (e) => {
        setExpanded(!expanded)
    }

    return (
        <>
            <div className={expanded ? tallCss : shortCss}>{contents}</div>{' '}
            <button
                className='btn btn-primary  '
                onClick={(e) => handleOnClick(e)}
                ref={moreButton}
            >
                {expanded ? 'Read less...' : 'Read more...'}
            </button>
        </>
    )
}
