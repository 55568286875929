// Reac
import React, { useEffect } from 'react'
// Components
import PageNav from '../shared/PageNav'
import History from './Members/History'
import Faqs from './Members/Faqs'
import Kata from './Members/Kata'
import Syllabus from './Members/Syllabus'
import Philosophy from './Members/Philosophy'

export default function Members() {
    const pages = ['History', 'FAQs', 'Syllabus', 'Philosophy', 'Kata']

    useEffect(() => {
        document.title =
            'Members - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    return (
        <div className='grey-out'>
            <History />
            <Faqs />
            <Syllabus />
            <Philosophy />
            <Kata />
            <PageNav pages={pages} />
        </div>
    )
}
