// React
import { React, useState, useRef, useEffect } from 'react'
// Components
import emailjs from 'emailjs-com'
import PageBanner from '../shared/PageBanner'
import { Messenger, Envelope, Facebook, Instagram } from 'react-bootstrap-icons'
// Lists
// Styles
import '../styles/contact.css'

export default function Contact() {
    useEffect(() => {
        document.title =
            'Contact - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const form = useRef()
    const submitButtonRef = useRef()
    const resetButtonRef = useRef()

    const setButtons = (enabled) => {
        submitButtonRef.current.disabled = !enabled
        resetButtonRef.current.disabled = !enabled
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        setButtons(false)
        emailjs
            .sendForm(
                'service_a7jqr2g',
                'JKA_Melbourne_Template',
                form.current,
                'user_ZK9JaNOM57WgGNdmwPEMp'
            )
            .then(
                (result) => {
                    alert(
                        'Thank you. Your message has been sent and we will get in touch with you soon.'
                    )
                    console.log(result.text)
                    handleReset(evt)
                },
                (error) => {
                    console.log(error.text)
                    setButtons(true)
                }
            )
    }

    const handleReset = (evt) => {
        evt.preventDefault()
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
        setButtons(true)
    }

    const TopBanner = () => {
        return (
            <PageBanner
                text={<h1>Contact us</h1>}
                image={'assets/contact.png'}
            ></PageBanner>
        )
    }

    const MessengerLink = () => {
        return (
            <>
                <div className='pb-3 text-center body-text'>
                    You can also contact us via our Social media account. Please
                    click the button below to open Messenger
                    <div className='pt-3'>
                        <a
                            href='https://m.me/jkamelbourne'
                            className='messenger contact-social-icon'
                            rel='noreferrer'
                        >
                            <Messenger /> Messenger
                        </a>
                    </div>
                </div>
            </>
        )
    }

    const SocialsLink = () => {
        return (
            <>
                <div className='pb-3 text-center body-text'>
                    Want to know what's up? You can visit our Facebook and
                    Instagram page for regular updates. Click the buttons below.
                    <div className='pt-3'>
                        <a
                            href='https://fb.com/jkamelbourne'
                            target={'_blank'}
                            rel='noreferrer'
                            className='facebook contact-social-icon'
                        >
                            <Facebook /> Facebook
                        </a>{' '}
                        <a
                            href='https://instagram.com/jkamelbourne'
                            target={'_blank'}
                            rel='noreferrer'
                            className='instagram contact-social-icon'
                        >
                            <Instagram /> Instagram
                        </a>
                    </div>
                </div>
            </>
        )
    }

    const EmailLink = () => {
        return (
            <>
                <div className='pb-3 text-center body-text'>
                    Alternatively, you can send us an email and we'll get back
                    to you as soon as we can. Use this button below.
                    <div className='pt-3'>
                        <a
                            href='mailto:ciaran.horgan@jkaaustralia.com.au'
                            className='email contact-social-icon'
                        >
                            <Envelope /> Email
                        </a>
                    </div>
                </div>
            </>
        )
    }

    const Body = () => {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 contact-left contact-group'>
                        <SocialsLink />
                        <MessengerLink />
                        <EmailLink />
                    </div>
                    <div className='col-md-6 contact-right contact-group'>
                        <form
                            className='form-group text-center '
                            ref={form}
                            onSubmit={handleSubmit.bind(this)}
                            onReset={handleReset.bind(this)}
                        >
                            <p>
                                Questions? Comments? Suggestions? We would love
                                to hear from you! Please use this form to let us
                                know what you think.
                            </p>
                            <input
                                type='text'
                                id='defaultContactFormName'
                                className='form-control required mb-4 '
                                name='name'
                                placeholder='Name'
                                required
                                minLength='1'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />

                            <input
                                type='email'
                                id='defaultContactFormEmail'
                                className='form-control mb-4'
                                name='email'
                                placeholder='E-mail'
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                type='phone'
                                id='defaultContactFormPhone'
                                className='form-control mb-4'
                                name='phone'
                                placeholder='Phone Number'
                                required
                                minLength='1'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />

                            <div className='form-group'>
                                <textarea
                                    className='form-control mb-4'
                                    id='exampleFormControlTextarea2'
                                    rows='3'
                                    name='message'
                                    placeholder='Message'
                                    required
                                    minLength='1'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </div>

                            <button
                                className='btn btn-primary m-1'
                                type='submit'
                                ref={submitButtonRef}
                            >
                                Send
                            </button>
                            <button
                                className='btn btn-secondary'
                                type='reset'
                                ref={resetButtonRef}
                            >
                                Reset
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const ContactForm = () => {
        return (
            <>
                <TopBanner />
                <div className='px-5 pb-5 body-text'>{Body()}</div>
            </>
        )
    }

    return <>{ContactForm()}</>
}
