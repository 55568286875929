export const videosList = [
    {
        id: 1,
        title: 'How to fold your Karate uniform',
        url: 'https://www.youtube.com/embed/LgIkks3ngDs?rel=0',
    },
    {
        id: 2,
        title: 'How to use your body in Karate',
        url: 'https://www.youtube.com/embed/KanPz-shqvw?rel=0',
    },
    {
        id: 3,
        title: 'How to kick faster',
        url: 'https://www.youtube.com/embed/iH5AF9DJH9U?rel=0',
    },
    {
        id: 4,
        title: 'Correct bowing for your body',
        url: 'https://www.youtube.com/embed/HN8nByO3y3c?rel=0',
    },
    {
        id: 5,
        title: 'Basic Karate training',
        url: 'https://www.youtube.com/embed/vaxFe50LzCk?rel=0',
    },
    {
        id: 6,
        title: 'How to train for core strength',
        url: 'https://www.youtube.com/embed/8FvzbPJ_iw8?rel=0',
    },

    {
        id: 7,
        title: 'Core strength: Arm+Leg Movements',
        url: 'https://www.youtube.com/embed/umj4Pj0-ZsU?rel=0',
    },

    {
        id: 8,
        title: 'How to tie Karate belt',
        url: 'https://www.youtube.com/embed/bD6Aw2uyHHA?rel=0',
    },
]
