// React
import React from 'react'

// Components
import { PageLocation } from '../../shared/PageNav'
import PageBanner from '../../shared/PageBanner'
import VideoModal from '../../shared/VideoModal'

// List
import { kataList } from '../../lists/kata'

// Styles
import '../../styles/members/kata.css'

export default function Kata() {
    const VideoItem = ({
        name,
        kanji,
        meaning,
        counts,
        first,
        second,
        url,
    }) => {
        return (
            <div className='card kata'>
                <VideoModal url={url} title={name + ' ' + kanji}></VideoModal>
                <div className='card-body'>
                    <div className='card-text'>
                        <div>
                            <b>{name}</b>
                            <div>
                                <b>{kanji}</b>
                            </div>
                        </div>
                        <div>{meaning} </div>
                        <div className='sub-text'>
                            <div>{counts} moves</div>
                            <div>1st kiai: {first} </div>
                            <div>2nd kiai: {second}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const VideoItemsList = ({ videoItemsList }) => {
        return videoItemsList.map((s) => {
            return (
                <VideoItem
                    key={s.id}
                    name={s.name}
                    kanji={s.kanji}
                    meaning={s.meaning}
                    counts={s.counts}
                    first={s.first}
                    second={s.second}
                    url={s.url}
                />
            )
        })
    }

    return (
        <>
            <PageLocation identifier={'Kata'} />
            <PageBanner image={'assets/members.png'} text='Kata'></PageBanner>
            <div className='videos text-center grey-out'>
                <VideoItemsList videoItemsList={kataList} />
            </div>
        </>
    )
}
