export const scheduleList = [
    {
        id: 1,
        day: 'Tuesday',
        time: '7:00pm - 8:15pm',
        location: 'Leinster Grove Community Hall',
        address: '99 Leinster Grove, Thornbury VIC 3071',
    },
    {
        id: 2,
        day: 'Saturday',
        time: '10:00am - 11:15am',
        location: 'Leinster Grove Community Hall',
        address: '99 Leinster Grove, Thornbury VIC 3071',
    },
]

export const mainLocation =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.2992735861067!2d144.9853711149228!3d-37.75958033871721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64499462a6303%3A0xe79097858e93846f!2s99%20Leinster%20Grove%2C%20Thornbury%20VIC%203071!5e0!3m2!1sen!2sau!4v1604040008811!5m2!1sen!2sau'
