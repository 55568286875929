import {
    Compass,
    Envelope,
    Facebook,
    GeoAlt,
    Instagram,
    Messenger,
    Puzzle,
} from 'react-bootstrap-icons'

export const footerItems = [
    {
        categoryId: 1,
        category: 'On the web',
        items: [
            {
                id: '11',
                href: 'https://www.jkamelbourne.com.au',
                label: 'jkamelbourne.com.au',
                icon: <Compass />,
            },
            {
                id: '12',
                href: 'mailto:ciaran.horgan@jkaaustralia.com.au',
                label: 'ciaran.horgan@jkaaustralia.com.au',
                icon: <Envelope />,
            },
            {
                id: '13',
                href: 'mailto:john.conrad.domingo@outlook.com',
                label: 'Built by John Domingo',
                icon: <Puzzle />,
            },
        ],
    },
    {
        categoryId: 2,
        category: 'Our socials',
        items: [
            {
                id: '21',
                href: 'https://www.facebook.com/jkamelbourne',
                label: 'fb.com/jkamelbourne',
                icon: <Facebook />,
            },
            {
                id: '22',
                href: 'https://instagram.com/jkamelbourne/',
                label: 'instagr.am/jkamelbourne',
                icon: <Instagram />,
            },
            {
                id: '23',
                href: 'https://m.me/jkamelbourne/',
                label: 'm.me/jkamelbourne',
                icon: <Messenger />,
            },
        ],
    },
    {
        categoryId: 3,
        category: 'Visit us',
        items: [
            {
                id: '31',
                href: 'https://www.google.com/maps/place/99+Leinster+Grove,+Thornbury+VIC+3071/@-37.7595846,144.9853711,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad64499462a6303:0xe79097858e93846f!8m2!3d-37.7595846!4d144.9875598',
                label: 'Leinster Grove Community Centre',
                icon: <GeoAlt />,
            },
        ],
    },
]

export const easySocialsList = [
    {
        id: 1,
        href: 'https://www.facebook.com/jkamelbourne/',
        label: 'fb.com/jkamelbourne',
        className: 'facebook',
        icon: <Facebook />,
    },
    {
        id: 2,
        href: 'https://www.instagram.com/jkamelbourne/',
        label: 'instagr.am/jkamelbourne',
        className: 'instagram',
        icon: <Instagram />,
    },

    {
        id: 3,
        href: 'https://m.me/jkamelbourne',
        label: 'm.me/jkamelbourne',
        className: 'messenger',
        icon: <Messenger />,
    },

    {
        id: 4,
        href: 'mailto:ciaran.horgan@jkaaustralia.com.au',
        label: 'ciaran.horgan@jkaaustralia.com.au',
        className: 'email',
        icon: <Envelope />,
    },
]
