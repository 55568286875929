// React
import React, { useEffect } from 'react'
// Components
import PageBanner from '../shared/PageBanner'
import { ArrowRight } from 'react-bootstrap-icons'
// Lists
import { scheduleList, mainLocation } from '../lists/schedules'
// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/join.css'

export default function Schedule() {
    useEffect(() => {
        document.title =
            'Join - Japan Karate Association: Melbourne Thornbury Branch'
    }, [])

    const ScheduleCard = ({ day, time, location, address }) => {
        return (
            <div className='col col-12 col-md-5 p-3 m-1 rounded glowcard'>
                <h3>{day}</h3>
                <div> {time}</div>
                <div>{location}</div>
                <div>{address}</div>
            </div>
        )
    }

    const ScheduleCardList = ({ schedules }) => {
        return schedules.map((s) => {
            return (
                <ScheduleCard
                    key={s.id}
                    day={s.day}
                    time={s.time}
                    location={s.location}
                    address={s.address}
                ></ScheduleCard>
            )
        })
    }
    const TopBanner = () => {
        return (
            <PageBanner
                text={<h1>Join us</h1>}
                image={'assets/join.png'}
            ></PageBanner>
        )
    }
    const MapGrid = () => {
        return (
            <>
                {' '}
                <div className='half '>
                    <div className='video-caption'>
                        <div className='text'>First 2 sessions are free</div>
                        <div className='text'>No lock-in contract</div>
                        <div className='text'>No complicated fee structure</div>
                        <button
                            className='join-button mt-3'
                            onClick={() => {
                                window.location =
                                    'https://www.jkaaustralia.com.au/member/'
                            }}
                        >
                            Register Now <ArrowRight />
                        </button>
                    </div>
                    <div className='join-video-container'>
                        <video
                            autoPlay
                            muted
                            playsInline
                            loop
                            width='170%'
                            style={{ marginLeft: '-50%' }}
                        >
                            <source src='assets/join.mov' type='video/mp4' />
                        </video>
                    </div>
                </div>
                <div className='half'>
                    <iframe
                        title='map-schedule'
                        className='map-schedule-container'
                        src={mainLocation}
                        width='300'
                        height='500'
                        frameBorder='0'
                        style={{ border: 0 }}
                        allowFullScreen=''
                        aria-hidden='false'
                        tabIndex='0'
                    ></iframe>
                </div>
            </>
        )
    }

    const Announcement = () => {
        return (
            <>
                <div className='announcement d-none'>
                    <div className='body-text px-4'>
                        We're happy to announce that we're training again on the
                        dojo starting <b>5 March 2022</b>! Keep an eye out on
                        our{' '}
                        <a
                            href='https://fb.com/jkaaustralia.com.au'
                            target='_blank'
                            rel='noreferrer'
                            className='p-link-green'
                        >
                            Facebook page
                        </a>{' '}
                        for updates. Otherwise, you can always{' '}
                        <a
                            href='/contact'
                            rel='noreferrer'
                            className='p-link-green'
                        >
                            send us a message
                        </a>
                        !
                    </div>
                </div>
            </>
        )
    }
    const Body = () => {
        return (
            <>
                {' '}
                <div className='px-4  body-text'>
                    <p>
                        Become a member of the Japan Karate Association: The
                        keeper of Karate's highest tradition. Join us in our
                        regular training on the location indicated here.
                        Everyone is welcome and we can't wait to see you there!
                    </p>

                    <p>
                        You can also fast-track your membership by registering
                        with JKA Australia. Make sure to choose{' '}
                        <b>Melbourne-Thornbury (VIC)</b> from the Branch list.
                        Once you have completed the form, it will be sent to our
                        instructor for approval. You will be contacted soon to
                        confirm your membership. We can't wait to officially
                        welcome and have you onboard!
                    </p>
                </div>
                <MapGrid />
                <Announcement />
                <div className='px-4 pb-5 body-text'>
                    <p>
                        Our uniform is called a Dogi however it is not necessary
                        to wear anything other than loose fitting clothes such
                        as tracksuit pants and top until you begin full time
                        training. All watches and jewellery should be removed.
                        Our JKAA Inc. Insurance policy will cover initial
                        evaluation students on a complimentary basis for these 2
                        sessions. If you feel as though you wish to continue
                        training fees will apply.
                    </p>

                    <div className='days-schedule container p-4'>
                        <div className='row'>
                            <ScheduleCardList schedules={scheduleList} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <TopBanner />

            <Body />
        </>
    )
}
