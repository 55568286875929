export const faqsList = [
    {
        id: 1,
        title: 'What is Karate?',
        content:
            "Karate means 'Empty hand' and is a traditional Japanese system of self-defence that employs blocks, punches, kicks, strikes, body evasion and occasional throws. It is an effective means of protecting oneself, but it also offers many other benefits such as: Aerobic conditioning Strengthens the body Strengthens the body Provides an outlet for stress Teaches children respect and discipline JKA Karate training can be broken into 3 areas of practice as follows: Kihon - Basic or fundamental training in all movements Kata - Pre-determined patterns of moves Kumite - Sparring",
    },
    {
        id: 2,
        title: 'What is Shotokan?',
        content:
            'Shotokan is the name of the style or system of karate practiced by JKAA, it literally means ‘House of Shoto’ which was our founder, Funakoshi Gichin Shihan’s calligraphy pen name and became the name of the first official dojo in Tokyo in 1939.',
    },
    {
        id: 3,
        title: 'Who are the JKA?',
        content:
            'The Japan Karate Association (JKA) are the World’s foremost authority on Karate. With a vast membership in over 100 countries, the JKA is the world’s largest and most prestigious karate organisation. JKA Australia is a member of this global organisation founded by Nishimura Takaatsu Sensei 7th Dan.',
    },
    {
        id: 4,
        title: 'What is JKA Philosophy?',
        content:
            'It is our belief that although Karate has a sporting aspect it is a traditional Japanese martial art and should be practiced as such. If required it is an effective form of self-defence and that it should instill in its practitioners discipline, control and confidence. Karate training must incorporate more than just the physical aspects; it must be firmly rooted in etiquette and respect. Our adult students tell us that karate improves sleep and increases energy. It offers a unique challenge not found in gyms. It is not just getting fit, but each level presents opportunities to learn and develop new skills. Parents tell us their children gain confidence and perform better in school.',
    },

    {
        id: 5,
        title: 'How to look after your belt',
        content:
            'Be mindful of your belt and treat it austerely. Remember a belt is part of your uniform. One’s belt should never be draped around the neck or cast unthinkingly aside. It is either tied correctly around your waist or placed neatly in your training bag.',
    },
    {
        id: 6,
        title: 'Late arrival for a class',
        content:
            'If you arrive late for a class the following procedure should be followed: 1. Take a position at the Dójó entrance and kneel in the Seiza position 2. Be sure that it is possible for the instructor to be aware of your presence 3. When the instructor acknowledges your presences usually by way of a quick bow and or the word Oss, you return the bow from the Seiza position and quickly join the class 4. This is a good opportunity to practice posture, etiquette and readiness.',
    },
    {
        id: 7,
        title: 'Cleanliness and maintenance of Dogi (Uniform)',
        content:
            'Hands and feet must be clean and nails trimmed neatly. It is also considered bad manners to train in an unclean Dogi. It is a simple matter of being aware that if your Dogi smells then there is a good chance that this will increase the discomfort of other students who are forced to train near you. Remember to air your Dogi after training. Through the course of your training it is common for a Dogi to become torn and/or discoloured; it is considered important that a Dogi is maintained correctly like any other uniform. All Dogi should be white with only one badge worn which is the association badge and positioned at the left side of the chest. Ladies are permitted to wear a white t-shirt under their Dogi but men are not.',
    },
    {
        id: 8,
        title: 'Can I wear jewellery?',
        content:
            'Karate training is a very austere and respectful art form A major element in its practice is the homogeneous nature of everyone taking part. Karate is seen as a means of spiritual improvement and it can therefore stand to reason that items of Jewellery and even cosmetics are seen as a distraction from the spiritual goal of Karate. Wearing Jewellery can also be seen as discriminatory, by leading to sense of segregation between rich and poor and it is for a similar reason why uniforms are worn at some schools and companies. Jewellery can also cause an injury to the wearer or other students so it must be removed or taped securely if removal is not an option.',
    },
    {
        id: 9,
        title: 'What is Sóji (Cleaning the Dójó)?',
        content:
            'It is general practice in the Dójó to clean the floor at the beginning and/or end of each training session. Often this is looked towards as an unfavourable chore however the underlying sentiment is that when you use something it should be maintained so that whoever uses the facility after you can experience the same sense of comfort as you experienced.',
    },
    {
        id: 10,
        title: 'Bowing (Rei)',
        content:
            'The regular Bowing in a traditional Karate class is seen as a form of austere acknowledgement. It is a practice that almost all traditional Japanese martial arts follow. Times when you bow include: Entering/leaving the dójó – silent bow 3 bows at the beginning/end of each class 1.Shómen ni rei – silent bow to the front 2.Sensei ni rei – bow to teacher(s) accompanied with the word ‘Oss’ 3.Otagai ni rei – bow to everyone accompanied with the word ‘Oss’. At the beginning/end of each kata – silent bow Before Yoi (Ready) and after Yame (Stop) commands – silent bow When beginning/ending a partner exercise or kumite bout accompanied with the word ‘Oss’ When addressed by a Sensei or Sempai accompanied with the word ‘Oss’ Kneeling bow if late for a class accompanied with the word ‘Oss’',
    },
    {
        id: 11,
        title: 'Bowing To Seniors',
        content:
            'It is courteous for all junior grades (kohai) on entering the Dójó prior to training to bow to senior grade (Sempai) 1st Dan Black belts and higher. Japanese Karate operates in a similar manner to the military does with saluting. Kohai should bow to Sempai as a sign of respect.',
    },
]
